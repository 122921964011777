import axios from 'axios'
import { show as showLoading } from '../redux/reducer/Loading'
import { setUsername, setCredit } from '../redux/reducer/Member'
import { set as setError } from '../redux/reducer/Error'
import store from '../redux/Store'

import LocalStorage from './LocalStorage'
import { LOCAL_STORAGE_IP, LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_MEMBER, LOCAL_STORAGE_URL_CALLBACK } from '../constants/LocalStorage'


axios.interceptors.request.use(function (config) {
  store.dispatch(showLoading(true))
  // Do something with request error
  return config
});

axios.interceptors.response.use(function (response) {
  store.dispatch(showLoading(false))

  return response;
}, function (error) {
  store.dispatch(showLoading(false))

  return Promise.reject(error);
})


const RequestLib = {

  get: async (url_path: string, direct?: boolean) => {
    const config = await RequestLib.header(url_path)
    config.method = 'get'

    return RequestLib.request(config, direct)
  },

  post: async (url_path: string, data: object, direct?: boolean) => {
    const config = await RequestLib.header(url_path)
    config.method = 'post'
    config.data = JSON.stringify(data)

    return RequestLib.request(config, direct)
  },

  delete: async (url_path: string, data: object, direct?: boolean) => {
    const config = await RequestLib.header(url_path)
    config.method = 'delete'
    config.data = JSON.stringify(data)

    return RequestLib.request(config, direct)
  },

  request: async (config: object, direct?: boolean) => {
    type Result = {
      response?: any;
      error?: any;
    }

    let result: Result = { response: null, error: null }

    try {
      const resp = await axios.request(config)
      if (resp?.data?.token) {
        LocalStorage.set(LOCAL_STORAGE_TOKEN, resp?.data?.token)
      }

      if (resp?.data?.callback_url) {
        LocalStorage.set(LOCAL_STORAGE_URL_CALLBACK, resp?.data?.callback_url)
      }


      const member_username = LocalStorage.get(LOCAL_STORAGE_MEMBER)
      if (member_username && resp?.data?.member) {
        if (member_username !== resp?.data?.member) {

          if (!direct) {
            result.error = new Error('unauthorized')

            store.dispatch(setError({ code: 403, message: result.error.message }))
          }

        } else {

          store.dispatch(setUsername(member_username))
          store.dispatch(setCredit(resp?.data?.credit))

        }
      }

      if (!result.error) result.response = resp
    } catch (error: any) {
      console.error('ERROR', error.message, error.response.status)
      result.error = error

      if (!direct) {
        store.dispatch(setError({ code: error.response.status, message: error.message }))
      }
    }

    return result
  },

  header: async (url_path: string) => {
    let url = process.env.REACT_APP_API_URL
    //if (url?.substring(-1) !== '/') url += '/'
    url += url_path

    const ip = await RequestLib.getIP()

    let headers = {
      'iv-remote-address': ip,
      'Content-Type': 'application/json',
      'Authorization': '',
    }

    const token = LocalStorage.get(LOCAL_STORAGE_TOKEN)
    if (token) headers['Authorization'] = 'Bearer ' + token

    let config = {
      method: '',
      maxBodyLength: Infinity,
      url: url,
      headers: headers,
      data: {},
    };

    return config
  }

  , getIP: async () => {
    const IP_DEFAULT = '127.0.0.1';

    let ip = LocalStorage.get(LOCAL_STORAGE_IP);
    if (!ip) ip = IP_DEFAULT
    if (!ip || ip === IP_DEFAULT) {

      try {
        let response = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
        if (response) {
          let data = await response.text()
          if (data) {
            for (const info of data.trim().split('\n')) {
              if (info.indexOf('=') !== -1) {
                const [field, value] = info.split('=')
                if (field.toLowerCase() === 'ip') ip = value
              }
            }
          }
        }
      } catch (error) { }

    }

    localStorage.setItem(LOCAL_STORAGE_IP, ip)

    return ip
  }

}

export default RequestLib