import { useEffect, useState } from 'react'
import { Container, Navbar } from 'react-bootstrap';


import { useAppSelector, useAppDispatch } from '../redux/Hook'

import { setCredit, setUsername } from '../redux/reducer/Member';

import IconBack from '../assets/images/back.png'
import IconBaht from '../assets/images/baht.png'
import IconReload from '../assets/images/reload.png'
import IconWarning from '../assets/images/warning.png'

import RequestLib from '../libs/Request'
import LocalStorage from '../libs/LocalStorage'
import { LOCAL_STORAGE_URL_CALLBACK } from '../constants/LocalStorage'


import '../assets/css/TopBar.css'

function TopBar() {
  const dispatch = useAppDispatch()

  const credit = useAppSelector((state) => state.member.credit)
  const username = useAppSelector((state) => state.member.username)
  const error_code = useAppSelector((state) => state.error.code)

  const [callbackUrl, setCallbackUrl] = useState<string>('')

  useEffect(() => {
    const callback_url = LocalStorage.get(LOCAL_STORAGE_URL_CALLBACK)
    if (callback_url) setCallbackUrl(callback_url)

  }, [])


  function callback() {

    LocalStorage.clear()

    window.location.href = callbackUrl

  }

  async function getCredit() {

    const { response, error } = await RequestLib.get('app/member')
    if (error) {
      console.error(error.response)
    } else {

      const member = response?.data?.member
      const username = member.nickname || response?.data?.member

      dispatch(setUsername(username))
      dispatch(setCredit(response?.data?.data?.credit))

    }
  }

  return (
    <>
      {!error_code &&

        <Navbar expand="xs" bg="dark" fixed="top">
          <Container fluid>
            <Navbar.Text>
              {callbackUrl && <a href="javacript:void(0)" onClick={callback}><img src={IconBack} className="icon-back" alt="" /></a>}
              <small>Hi! <strong>{username}</strong></small>
            </Navbar.Text>
            <Navbar.Text className="justify-content-end">
              <img src={IconBaht} className="icon-coin" alt="" />

              {(credit >= 0) ? (
                <small>{credit}</small>
              ) : (
                <>
                  <img src={IconWarning} className="icon-reload" alt={'ไม่สามารถึงข้อมูลเครดิตของ ' + username} />
                </>
              )}
              <img src={IconReload} className="icon-reload" alt="คลิกเพื่อดึงข้อมูลเครดิต" onClick={getCredit} />

            </Navbar.Text>
            {/*
            <Navbar.Toggle></Navbar.Toggle>

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand`}
              aria-labelledby={`offcanvasNavbarLabel-expand`}
              placement="end"
            >

              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                  {username}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                Some text as placeholder. In real life you can have the elements you
                have chosen. Like, text, images, lists, etc.
              </Offcanvas.Body>

            </Navbar.Offcanvas>
              */}

          </Container>
        </Navbar>

      }

    </>
  )
}


export default TopBar