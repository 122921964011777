import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../Store'


import LocalStorage from '../../libs/LocalStorage'
import { LOCAL_STORAGE_MEMBER, LOCAL_STORAGE_AVATAR } from '../../constants/LocalStorage'

interface MemberState {
  username: string
  credit: number
  avatar: string
}

const initialState: MemberState = {
  credit: -1,
  username: '-',
  avatar: '',
}

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setCredit: (state, action: PayloadAction<number>) => {
      if (action.payload) state.credit = action.payload
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload

      LocalStorage.set(LOCAL_STORAGE_MEMBER, state.username)
    },
    setAvatar: (state, action: PayloadAction<string>) => {
      state.avatar = action.payload

      LocalStorage.set(LOCAL_STORAGE_AVATAR, state.avatar)
    }
  }
})

export const { setCredit, setUsername, setAvatar } = memberSlice.actions
export const getMemberCredit = (state: RootState) => state.member.credit
export const getMemberUsername = (state: RootState) => state.member.username
export const getMember = (state: RootState) => state.member
export default memberSlice.reducer