import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../Store'


interface LoadingState {
  show: boolean
}

const initialState: LoadingState = {
  show: false,
}


export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload
    }
  }
})

export const { show } = loadingSlice.actions

export const getShow = (state: RootState) => state.loading.show

export default loadingSlice.reducer