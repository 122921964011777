import { useEffect, useState } from 'react'

import { find } from 'lodash'

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { useAppDispatch } from '../redux/Hook'
import { set as setError } from '../redux/reducer/Error'

import RequestLib from '../libs/Request'

interface Agent {
  id: string;
  code: string;
  name: string;
  access_key: string;
}

interface FormData {
  agent: string;
  username: string;
}

function MainPage() {
  const dispatch = useAppDispatch()

  const [bypass, setBypass] = useState(false)
  const [agents, setAgents] = useState<Agent[]>([])
  const [form, setForm] = useState<FormData>({ agent: '', username: '' })

  useEffect(() => {

    // 
    if (process.env.REACT_APP_BYPASS && process.env.REACT_APP_BYPASS === 'y') {

      setBypass(true)

      loadBypass()

    } else {
      setBypass(false)
      dispatch(setError({ code: 404, message: 'Not Found' }))
    }

  }, [dispatch])


  async function loadBypass() {
    setAgents([])

    const { response, error } = await RequestLib.get('app/game/agents', true)
    if (error) {
      console.error(error)
    } else {

      setAgents(response.data?.data?.agents)

    }

  }


  async function access() {
    if (form.agent && form.username) {

      const ag = find(agents, { id: form.agent })
      if (ag) {
        console.log(ag)

        let data = {
          "agent_id": ag.id,
          "access_key": ag.access_key,
          "username": form.username,
        }



        const { response, error } = await RequestLib.post('app/authen/access', data, true)
        if (error) {
          console.error(error.response?.data)
          alert(error.response?.data?.message || 'ไม่สามารถใช้งานได้ขณะนี้')
        } else {

          const game_url = response.data?.data?.game_url

          window.location.href = game_url

        }

      }

    }
  }

  return (
    <>

      {bypass &&
        <Form className="py-4">
          <Form.Group className="mb-4" >
            <Form.Label>AGENT</Form.Label>
            <Form.Select size="lg" onChange={(e) => setForm({ ...form, agent: e.target.value })}>
              <option value=""></option>
              {agents.map((agent, i) => (<option value={agent?.id} key={i}>{agent.code} - {agent.name}</option>))}

            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 " >
            <Form.Label htmlFor="inputPassword5">Username ที่จะใช้เล่น</Form.Label>
            <Form.Control type="text" placeholder={''} size="lg" onChange={(e) => setForm({ ...form, username: e.target.value })} />
          </Form.Group>
          <Form.Group className="mb-3 text-center" >
            <Button size="lg" className="px-4" onClick={access} disabled={!(form.agent && form.username)} >ตกลง</Button>
          </Form.Group>
        </Form>
      }


    </>
  )
}

export default MainPage