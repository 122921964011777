import { configureStore } from '@reduxjs/toolkit'
// ...

import MemberReducer from './reducer/Member'
import LoadingReducer from './reducer/Loading'
import ErrorReducer from './reducer/Error'

const store = configureStore({
  reducer: {
    member: MemberReducer,
    loading: LoadingReducer,
    error: ErrorReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store