
import IconUnauthorized from '../../assets/images/unauthorized.png'

import '../../assets/css/Unauthorized.css'

function UnauthorizedPage() {


  return (
    <div className='text-center'>
      <img src={IconUnauthorized} alt="" className='img-warning py-4' />
    </div>
  )
}

export default UnauthorizedPage