import { useEffect, useState } from 'react'

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


import { useAppDispatch } from '../redux/Hook'
import { setCredit } from '../redux/reducer/Member';

import RequestLib from '../libs/Request'
import LocalStorage from '../libs/LocalStorage'
import { LOCAL_STORAGE_GAME_CALLBACK, LOCAL_STORAGE_TOKEN } from '../constants/LocalStorage'

function GamesPage() {
  const dispatch = useAppDispatch()


  const [games, setGames] = useState<{ id: string, name: string, icon: string, excerpt: string }[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const { response, error } = await RequestLib.get('app/games')
      if (error) {
        console.error(error)
      } else {

        dispatch(setCredit(response?.data?.credit))

        setGames(response?.data?.data?.games)

      }

    }

    fetchData()

  }, [dispatch])

  async function play(gameid: string) {
    let callback_url = LocalStorage.get(LOCAL_STORAGE_GAME_CALLBACK)
    let token = LocalStorage.get(LOCAL_STORAGE_TOKEN)

    callback_url += token

    const { response, error } = await RequestLib.post('app/game/play', { game: gameid, callback_url: callback_url })
    if (error) {
      console.error(error)
    } else {

      const url: string = response?.data?.data?.game?.url

      window.location.href = url

    }
  }

  return (

    <div className='py-4'>
      {games.length > 0 &&

        <Row xs={2} md={2} className="g-4">
          {games.map((game, idx) => (
            <Col key={idx}>
              <Card bg="dark" border="dark" onClick={() => play(game.id)}>
                <Card.Img variant="top" src={game?.icon} />
                <Card.Body>
                  <Card.Title className='text-center'>{game?.name}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

      }
    </div>
  )
}


export default GamesPage