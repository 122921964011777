

function GamesPage() {


  return (
    <>
    </>
  )
}


export default GamesPage