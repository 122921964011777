import { useEffect } from 'react'
import { Outlet, useNavigate } from "react-router-dom";

import { useAppSelector } from '../redux/Hook'

import TopBar from './TopBar'

function LayoutComponent() {
  const navigate = useNavigate()
  const error_code = useAppSelector((state) => state.error.code)


  useEffect(() => {

    if (error_code) navigate('/unauthorized')

  }, [error_code, navigate])

  return (
    <>
      <TopBar />
      <Outlet />
    </>
  )
}

export default LayoutComponent