
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import LoadingOverlay from 'react-loading-overlay-ts';


import { useAppSelector } from './redux/Hook'


import Layout from './components/Layout'

import MainPage from './pages/Main'
import AccessPage from './pages/Access'
import GamesPage from './pages/Games'
import GamePage from './pages/Game'
import UnauthorizedPage from './pages/error/Unauthorized'



const router = createBrowserRouter([
  {
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        Component: MainPage,
      },
      {
        path: "access/:token?",
        Component: AccessPage,
      },
      {
        path: "games",
        Component: GamesPage,
      },
      {
        path: "game/:id",
        Component: GamePage,
      },
    ],
  },
  {
    path: "/unauthorized",
    Component: UnauthorizedPage,
  },
])


function App() {
  const showLoading = useAppSelector((state) => state.loading.show)


  return (
    <>
      <LoadingOverlay
        active={showLoading}
        spinner
        text='Please wait...'
      >
        <Container fluid>
          <RouterProvider router={router} />
        </Container>
      </LoadingOverlay>
    </>

  );
}

export default App;
