import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ErrorState {
  code: number
  message: string
}

const initialState: ErrorState = {
  code: 0,
  message: '',
}

type ErrorPayload = {
  code: number
  message: string
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<ErrorPayload>) => {
      const { code, message } = action.payload

      state.code = code
      state.message = message
    },
  }
})

export const { set } = errorSlice.actions
export default errorSlice.reducer