import { isObject } from 'lodash'

var LocalStorage = {
  set: (name: string, data: any) => {
    let value = isObject(data) ? JSON.stringify(data) : data

    localStorage.setItem(name, value)
  },

  get: (name: string): string => {
    let value = localStorage.getItem(name) || ''
    return value
  },

  remove: (name: string) => {
    localStorage.removeItem(name)
  },

  clear: () => {
    localStorage.clear()
  }
}



export default LocalStorage