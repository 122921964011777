import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './Store'

import { show } from './reducer/Loading'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useShowLoading = () => {
  const dispatch = useAppDispatch()

  return (payload: any) => {
    dispatch(show(payload));
  }
}