import { useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";

import { useAppDispatch } from '../redux/Hook'
import { setUsername, setCredit } from '../redux/reducer/Member';

import LocalStorage from '../libs/LocalStorage'
import { LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_GAME_CALLBACK } from '../constants/LocalStorage'

import RequestLib from '../libs/Request'

function AccessPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const { token } = useParams<"token">()


  useEffect(() => {
    LocalStorage.clear()

    const fetchData = async () => {
      const { response, error } = await RequestLib.post('app/authen/check', { token: token })
      if (error) {
        console.error(error)
        navigate('/unauthorized')
      } else {

        if (token) {
          if (window.location.href, window.location.href.indexOf(token || '  ') !== -1) {
            LocalStorage.set(LOCAL_STORAGE_GAME_CALLBACK, window.location.href.replace(token, ''))
          }
        }

        LocalStorage.set(LOCAL_STORAGE_TOKEN, token)
        dispatch(setUsername(response?.data?.data?.member || ''))

        if (response?.data?.data?.credit) {
          dispatch(setCredit(response?.data?.data?.credit))
        }

        navigate('/games')
      }

    }

    if (token) fetchData()
    else navigate('/unauthorized')

  }, [token, dispatch, navigate])




  return (
    <>
    </>
  )

}


export default AccessPage